import { t } from 'shared/i18n';
import SimpleLikingAPI from 'shared/utils/SimpleLikingAPI';
// eslint-disable-next-line import/no-unresolved
import '../styles-compressed/legacy/simple-liking.css';
// eslint-disable-next-line import/no-unresolved
import '../styles-compressed/legacy/form-rendering-utils.css';
/**
 * @module squarespace-simple-liking
 */
YUI.add('squarespace-simple-liking', function (Y) {

  var Static = Y.config.win.Static;

  /**
   * @namespace Squarespace
   * @class SimpleLike
   * @static
   */
  Y.Squarespace.SimpleLike = {
    TEXT_LIKE_VERB_NONE: t("No Likes"),
    TEXT_LIKE_VERB_SINGULAR: t("Like"),
    TEXT_LIKE_VERB_PLURAL: t("Likes"),
    TEXT_LIKE_ACTIVATED: t("You like this"),
    TEXT_LIKE_SUGGEST: t("Click to like"),

    attached: false,

    /**
     * Happens automatically
     * @method attach
     */
    attach: function () {
      // once only
      if (this.attached) {return;}
      this.attached = true;

      // try and get likes from local storage
      var previousLikes;

      try {
        if (localStorage) {
          previousLikes = Y.JSON.parse(localStorage.getItem('squarespace-likes'));
          if (!previousLikes) {
            previousLikes = {};
          }
          localStorage.setItem('squarespace-likes', Y.JSON.stringify(previousLikes));
        }
      } catch (e) {
        previousLikes = {};
      }

      // add actions

      this.eventHandlers = [];

      var clickHandler = Y.delegate('click', function clickedLike(e) {
        /* @type {YUI.Node} */
        var n = e.currentTarget;

        // fail on invalid items
        var itemId = n.getAttribute('data-item-id');
        if (!itemId) {
          return;
        }

        // animate
        for (var i = 0; i < 5; ++i) {Y.Squarespace.SimpleLike.burst(n);}

        // send only once
        if (n.hasClass('clicked') || n.hasClass('float')) {
          return;
        }

        SimpleLikingAPI.like(itemId).
        then(function (response) {
          if (response.commited === true) {
            // DOM updates
            if (n && n.getDOMNode()) {
              var likeCount = parseInt(n.getAttribute('data-like-count'), 10);
              n.setAttribute('data-like-count', likeCount + 1);
              Y.Squarespace.SimpleLike.renderLikeCount(n);
              n.addClass('clicked');
            }

            // save to local storage
            try {
              if (localStorage) {
                previousLikes[itemId] = true;
                localStorage.setItem('squarespace-likes', Y.JSON.stringify(previousLikes));
              }
            } catch (err) {
              console.warn('Unable to save like to local storage: ', err);
            }
          }

          if (response.error) {
            if (!Y.Squarespace.Utils.areCookiesEnabled()) {
              Y.config.win.alert(t("You need to enable cookies to be able to like something."));


            }
          }
        }).
        catch(function (error) {
          if (__DEV__) {
            console.error('Something went wrong liking!', error);
          }
        });

      }, 'body', '.sqs-simple-like');

      this.eventHandlers.push(clickHandler);

      // initialize nodes
      Y.all('.sqs-simple-like').each(function (n) {
        var itemId = n.getAttribute('data-item-id');
        if (!itemId) {return;}

        // are they disabled?
        if (!Static.SQUARESPACE_CONTEXT.websiteSettings.simpleLikingEnabled) {
          n.remove();
        }

        // try and restore previous like actions
        if (previousLikes[itemId]) {
          n.addClass('clicked');
        }

        // This is an asynchronous request. The API request may complete AFTER
        // iframe is destroyed, so we must check than nEl is still attached to
        // an ownerDocument with a defaultView before doing any rendering.
        SimpleLikingAPI.getLikeCount(itemId).
        then(function withLikeCount(freshLikeCount) {
          n.setAttribute('data-like-count', freshLikeCount);
          Y.Squarespace.SimpleLike.renderLikeCount(n);
        }).
        catch(function withoutLikeCount() {
          Y.Squarespace.SimpleLike.renderLikeCount(n);
        });
      });

    },

    detach: function () {
      this.attached = false;
      this.eventHandlers.forEach(function (handler) {
        handler.detach();
      });

    },

    /**
     * @param {YUI.Node} n
     */
    renderLikeCount: function simpleLikingRenderLikeCount(n) {
      // The node is no longer attached to a live document (maybe the API
      // request was too slow and user navigated iframe to a new document).
      var nEl = n && n.getDOMNode ? n.getDOMNode() : n;
      if (!nEl || !nEl.ownerDocument || !nEl.ownerDocument.defaultView) {
        return;
      }

      var itemId = n.getAttribute('data-item-id');
      var likeCount = n.getAttribute('data-like-count');
      var countNode = n.one('.like-count');
      if (!itemId || !countNode) {
        return;
      }
      countNode.setContent(this.formatLikeText(likeCount));
    },

    formatLikeText: function (likeCount) {
      if (likeCount === 0) {
        return Y.Squarespace.SimpleLike.TEXT_LIKE_VERB_NONE;
      } else if (likeCount === 1) {
        return '1 ' + Y.Squarespace.SimpleLike.TEXT_LIKE_VERB_SINGULAR;
      }

      return likeCount + ' ' + Y.Squarespace.SimpleLike.TEXT_LIKE_VERB_PLURAL;
    },

    burst: function (n) {
      var clone = n.cloneNode(true);
      if (clone.one('.like-count')) {
        clone.one('.like-count').remove();
      }

      var iconNode = n.one('.like-icon');
      if (!iconNode || iconNode.getStyle('display') === 'none') {
        iconNode = n;
      }

      clone.setStyles({
        position: 'absolute',
        left: iconNode.getX() + 'px',
        top: iconNode.getY() + 'px' });


      Y.one(Y.config.doc.body).append(clone);

      // float

      var a = new Y.Anim({
        node: clone,
        duration: 2,
        easing: Y.Easing.easeOut });

      a.set('to', {
        curve: Y.Squarespace.SimpleLike.randomCurve(clone) });

      a.on('end', function () {
        this.get('node').remove();
      });
      a.run();

      clone.removeClass('clicked');
      clone.addClass('float');
    },

    randomCurve: function (node) {
      var points = [];
      var n = 3;
      var mag = 30;
      var xdir = Math.floor(Math.random() * 2) ? 1 : -1;
      var ydir = Math.floor(Math.random() * 2) ? 1 : -1;
      var x = node.getX();
      var y = node.getY();
      var i = 0;

      for (; i < n; ++i) {
        x += Math.floor(Math.random() * mag) * xdir;
        y += Math.floor(Math.random() * mag) * ydir;
        points.push([x, y]);
      }

      return points;
    } };



  // ------------------------------------------------------------------------------------------------
  // Initialize
  // ------------------------------------------------------------------------------------------------

  Y.config.win.Squarespace.onInitialize(Y, function () {
    if (Static.SQUARESPACE_CONTEXT.websiteSettings.simpleLikingEnabled) {
      Y.Squarespace.SimpleLike.attach();
    }
  });

  Y.config.win.Squarespace.onDestroy(Y, function () {
    if (Y.Squarespace.SimpleLike.attached === true) {
      Y.Squarespace.SimpleLike.detach();
    }
  });

}, '1.0', {
  requires: [
  'anim',
  'json',
  'node',
  'squarespace-util'] });