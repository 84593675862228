import network from '@sqs/network';

const ID_SEPERATOR = ',';

const like = itemId =>
  network.post('/api/content-items/' + itemId + '/sentiment/like')
    .then(({ data }) => data);

let pendingRequests = [];
let pendingTimer = null;

const processPendingRequests = () => {
  const itemIds = pendingRequests.map(entry => entry.itemId).join(ID_SEPERATOR);
  network.get(`/api/blog-like-count/${itemIds}`).then(({ data }) => {
    pendingRequests.forEach(request => {
      if (data.hasOwnProperty(request.itemId)) {
        request.resolve(data[request.itemId]);
        return;
      }

      request.reject();
    });

    pendingRequests = [];
    pendingTimer = null;
  });
};

const queueLikeCount = itemId => new Promise((resolve, reject) => {
  pendingRequests.push({
    itemId,
    resolve,
    reject
  });

  if (pendingTimer !== null) {
    clearTimeout(pendingTimer);
  }

  pendingTimer = setTimeout(processPendingRequests, 100);
});

const getLikeCount = itemId => queueLikeCount(itemId);

export default { like, getLikeCount };